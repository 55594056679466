import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import moment, { isMoment } from 'moment';
import _ from 'lodash';

// MUI
import {
  Autocomplete,
  Card,
  Chip,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton, TimePicker, DatePicker } from '@mui/lab';

// Icon
import CalendarIcon from '@mui/icons-material/CalendarToday';

// Form
import * as Yup from 'yup';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Types
import { OperatingHour, OperatingHourFormType } from '../../@types/operatingHourSchedule';
import { ChargingPoint } from '../../@types/chargingPoint';

// Hooks
import { useOperatingHours } from 'features/operatingHours/operatingHours';

// API
import { apiGetChargingPointsLean } from 'features/dashboard/apis';

interface DaysOptionType {
  title: string;
  value: string;
}

const daysOptions = [
  { title: 'Monday', value: 'monday' },
  { title: 'Tuesday', value: 'tuesday' },
  { title: 'Wednesday', value: 'wednesday' },
  { title: 'Thursday', value: 'thursday' },
  { title: 'Friday', value: 'friday' },
  { title: 'Saturday', value: 'saturday' },
  { title: 'Sunday', value: 'sunday' }
];

const schema = Yup.object().shape({
  name: Yup.string().typeError('Required').required('Required'),
  status: Yup.string().typeError('Required').required('Required'),
  description: Yup.string().typeError('Required').required('Required'),
  openingHour: Yup.string().typeError('Required').required('Required'),
  closingHour: Yup.string().typeError('Required').required('Required'),
  closingOffsetMinute: Yup.string().typeError('Required').required('Required'),
  excludeDates: Yup.string(),
  operatingDays: Yup.array().min(1).required('Required'),
  chargingPoints: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().typeError('Required').required('Required')
      })
    )
    .min(1)
    .required('Required')
});

const OperatingHoursForm = ({
  data,
  isEdit = false,
  id = ''
}: {
  data?: OperatingHour;
  isEdit?: boolean;
  id?: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { submitOperatingHour } = useOperatingHours({ isEdit, id });

  const formatOperatingDays = useCallback((data: any) => {
    if (!data) return [];
    return daysOptions.filter((i) => data[i.value]);
  }, []);

  const methods = useForm<OperatingHourFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name,
      status: data?.status || 'ACTIVE',
      description: data?.description,
      openingHour: data?.openingHour
        ? moment(data.openingHour, 'HH:mm:ss.SSS').utcOffset('+0700').toISOString()
        : '',
      closingHour: data?.closingHour
        ? moment(data.closingHour, 'HH:mm:ss.SSS').utcOffset('+0700').toISOString()
        : '',
      openingOffsetMinute: data?.openingOffsetMinute,
      closingOffsetMinute: data?.closingOffsetMinute,
      excludeDates: data?.excludeDates,
      operatingDays: formatOperatingDays(data),
      chargingPoints:
        data?.chargingPoints?.map((i) => ({
          ...i,
          title: `${i.serialNumber} ${i.name ? `(${i.name})` : ' '}`
        })) || []
    }
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty }
  } = methods;

  const onSubmit = handleSubmit(submitOperatingHour);

  const { data: chargingPoints } = useQuery<ChargingPoint[], Error>(
    'chargingPointsLean',
    apiGetChargingPointsLean,
    { refetchOnWindowFocus: false }
  );

  const handleSelecetedTags = (items: any) => {
    console.log('items:', items);
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <TextField
                  {...register('status')}
                  defaultValue={data?.status || 'ACTIVE'}
                  select
                  label="Status"
                  error={Boolean(errors?.status)}
                  helperText={errors?.status?.message}
                  disabled={isSubmitting}
                  fullWidth
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </TextField>

                <TextField
                  {...register('name')}
                  fullWidth
                  label="Name"
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                  disabled={isSubmitting}
                />

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Controller
                    control={control}
                    name="openingHour"
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        ampm={false}
                        onChange={(value) =>
                          isMoment(value)
                            ? field.onChange((value as moment.Moment).toISOString())
                            : field.onChange(value)
                        }
                        label="Opening Hour"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            error={Boolean(errors?.openingHour)}
                            helperText={errors?.openingHour?.message}
                          />
                        )}
                        disabled={isSubmitting}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="closingHour"
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        ampm={false}
                        onChange={(value) =>
                          isMoment(value)
                            ? field.onChange((value as moment.Moment).toISOString())
                            : field.onChange(value)
                        }
                        label="Closing Hour"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            error={Boolean(errors?.closingHour)}
                            helperText={errors?.closingHour?.message}
                          />
                        )}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    {...register('openingOffsetMinute')}
                    fullWidth
                    label="Offset Opening Minute"
                    error={Boolean(errors?.openingOffsetMinute)}
                    helperText={errors?.openingOffsetMinute?.message}
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography>Minutes</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    {...register('closingOffsetMinute')}
                    fullWidth
                    label="Offset Closing Minute"
                    error={Boolean(errors?.closingOffsetMinute)}
                    helperText={errors?.closingOffsetMinute?.message}
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography>Minutes</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

                <Controller
                  control={control}
                  name="excludeDates"
                  render={({ field }) => {
                    const excludeDates = _.uniq(
                      field?.value?.split('\n')?.filter((date) => date.length > 0)
                    );

                    return (
                      <DatePicker
                        {...field}
                        value=""
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        onChange={(value) => {
                          if (isMoment(value)) {
                            const newExcludeDates = _.uniq([
                              ...excludeDates,
                              (value as moment.Moment).format('YYYY-MM-DD')
                            ]);
                            field.onChange(newExcludeDates.join('\n'));
                          }
                        }}
                        label="Exclude Dates"
                        PopperProps={{
                          placement: 'bottom-end',
                          anchorEl: anchorEl
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              // {...params}
                              label="Exclude Dates"
                              InputProps={{
                                endAdornment: (
                                  <CalendarIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(event) => {
                                      setOpen(true);
                                      setAnchorEl(event?.currentTarget);
                                    }}
                                  />
                                ),
                                startAdornment: (
                                  <Grid
                                    container
                                    flexDirection="row"
                                    direction="row"
                                    spacing={1}
                                    sx={{ padding: 1 }}
                                  >
                                    {!excludeDates.length && (
                                      <Grid item xs={6}>
                                        <Typography sx={{ color: '#B0B8C1' }}>
                                          Select Exclude Day
                                        </Typography>
                                      </Grid>
                                    )}
                                    {excludeDates.map((date: string, index: number) => (
                                      <Grid
                                        item
                                        xs
                                        key={index}
                                        sx={{ maxWidth: '140px !important' }}
                                      >
                                        <Chip
                                          sx={{ mr: 1, width: '100%' }}
                                          key={index}
                                          tabIndex={-1}
                                          label={date}
                                          onDelete={() => {
                                            const newExcludeDates = _.uniq(
                                              excludeDates.filter((i) => i !== date)
                                            );
                                            field.onChange(newExcludeDates.join('\n'));
                                          }}
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                ),
                                sx: { '& input': { width: '0' } }
                              }}
                              fullWidth
                              // multiline
                              // rows={4}
                              error={Boolean(errors?.excludeDates)}
                              helperText={errors?.excludeDates?.message}
                              disabled={isSubmitting}
                              placeholder="Select Exclude Day"
                            />
                          );
                        }}
                        disabled={isSubmitting}
                      />
                    );
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <TextField
                  {...register('description')}
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  error={Boolean(errors?.description)}
                  helperText={errors?.description?.message}
                  disabled={isSubmitting}
                />

                <Controller
                  control={control}
                  name="operatingDays"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disabled={isSubmitting}
                      options={daysOptions}
                      getOptionLabel={(option: DaysOptionType) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Operating Days"
                          placeholder="Select Day"
                          error={Boolean(errors?.operatingDays)}
                          helperText={errors?.operatingDays && 'Required'}
                        />
                      )}
                      onChange={(e, value) => field.onChange(value)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="chargingPoints"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disabled={isSubmitting}
                      options={
                        chargingPoints?.map((i) => ({
                          title: `${i.serialNumber} ${i.name ? `(${i.name})` : ' '}`,
                          id: i.id
                        })) || []
                      }
                      getOptionLabel={(option: { title: string }) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Charging Points"
                          placeholder="Select Charging Point"
                          error={Boolean(errors?.chargingPoints)}
                          helperText={Boolean(errors?.chargingPoints) && 'Required'}
                        />
                      )}
                      onChange={(e, value) => field.onChange(value)}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>

          <Stack alignItems="center" mt={4}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting || !isDirty}
              sx={{ width: { xs: '100%', md: '350px' } }}
            >
              {isEdit ? 'Save' : 'Create'}
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </Card>
  );
};

export default OperatingHoursForm;
