import { ReactNode } from 'react';
import { isString } from 'lodash';
// material
import { Box, Card, Typography, Link } from '@mui/material';
//
import { MBreadcrumbs } from './@material-extend';
import { MBreadcrumbsProps } from './@material-extend/MBreadcrumbs';

// ----------------------------------------------------------------------

interface HeaderBreadcrumbsProps extends MBreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}: HeaderBreadcrumbsProps) {
  return (
    <Card sx={{ p: 3, mb: 3 }}>
      <Box sx={{ ...sx }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
            <MBreadcrumbs links={links} {...other} />
          </Box>

          {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        </Box>

        <Box sx={{}}>
          {isString(moreLink) ? (
            <Link href={moreLink} target="_blank" variant="body2">
              {moreLink}
            </Link>
          ) : (
            moreLink.map((href) => (
              <Link
                noWrap
                key={href}
                href={href}
                variant="body2"
                target="_blank"
                sx={{ display: 'table' }}
              >
                {href}
              </Link>
            ))
          )}
        </Box>
      </Box>
    </Card>
  );
}
