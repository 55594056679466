import { useEffect } from 'react';
import { useQuery, useQueryClient, useInfiniteQuery } from 'react-query';
import throttle from 'lodash/throttle';

// apis
import { apiGetNotificationsPaginationScroll, apiGetNotifications } from './apis';
import { Notification } from '../../@types/notification';

export const useGetNotificationsPaginationScroll = (params: Record<string, any>) => {
  const defaultQuery = {
    _limit: 10,
    _sort: 'createdAt:DESC',
    ...params
  };

  useEffect(() => {
    window.onscroll = throttle(() => {
      if (query.isLoading || query.isFetchingNextPage || !query.hasNextPage) return;

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight * 0.85) {
        query.fetchNextPage();
      }
    }, 100);
  });

  const query = useInfiniteQuery(
    ['getNotificationPaginationScroll', defaultQuery],
    apiGetNotificationsPaginationScroll,
    {
      getNextPageParam: (lastPage: any) => (lastPage?.hasNextPage ? lastPage?.nextPage : undefined),
      keepPreviousData: true,
      staleTime: 60000,
      refetchInterval: 60000,
      refetchOnWindowFocus: true
    }
  );

  return {
    ...query,
    data: query?.data?.pages || [],
    count: query?.data?.pages?.[0]?.count || 0
  };
};

export const useGetNotifications = (queryParam: Record<string, any>) => {
  const query = useQuery<Notification[], Error>(
    ['getNotifications'],
    () => apiGetNotifications(queryParam),
    {
      keepPreviousData: true,
      staleTime: 60000,
      refetchInterval: 60000,
      refetchOnWindowFocus: true
    }
  );

  return {
    ...query,
    data: query?.data || []
  };
};
