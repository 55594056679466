import { useAuth } from 'features/authentication';
import { useContext, useMemo } from 'react';
import axios from './axios';

const WithAxios = ({ children }: { children: any }) => {
  const { logout } = useAuth();

  useMemo(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const errorStatuses = [401];
        if (errorStatuses.includes(error?.statusCode)) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }, [logout]);

  return children;
};

export default WithAxios;
