// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// MUI Icons
import MapIcon from '@mui/icons-material/Map';
import EvStationIcon from '@mui/icons-material/EvStation';
import AdjustIcon from '@mui/icons-material/Adjust';
import GroupIcon from '@mui/icons-material/Group';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ComputerIcon from '@mui/icons-material/Computer';
// import InventoryIcon from '@mui/icons-material/Inventory';
// import DownloadIcon from '@mui/icons-material/Download';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const adminSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.analytics },
      { title: 'Session', path: PATH_DASHBOARD.general.session, icon: <AdjustIcon /> },
      { title: 'Map', path: PATH_DASHBOARD.general.map, icon: <MapIcon /> },
      {
        title: 'Analytics',
        path: PATH_DASHBOARD.analytics.root,
        icon: <DataUsageIcon />,
        children: [
          { title: 'Energy Analytics', path: PATH_DASHBOARD.analytics.energyAnalytics },
          { title: 'Session Analytics', path: PATH_DASHBOARD.analytics.sessionAnalytics }
          // { title: 'User Analytics', path: PATH_DASHBOARD.analytics.userAnalytics },
          // { title: 'Financial Analytics', path: PATH_DASHBOARD.analytics.financialAnalytics }
        ]
      },
      {
        title: 'Operating Hours',
        path: PATH_DASHBOARD.general.operatingHour,
        icon: <AccessAlarmIcon />
      }
    ]
  },

  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'STATION',
    items: [
      {
        title: 'Stations',
        path: PATH_DASHBOARD.stations.station,
        icon: <EvStationIcon />,
        children: [
          {
            title: 'GWM Stations',
            path: PATH_DASHBOARD.stations.gwmStation,
            icon: <EvStationIcon />,
            isItem: true
          },
          {
            title: 'Partner Stations',
            path: PATH_DASHBOARD.stations.partnerStation,
            icon: <EvStationIcon />,
            isItem: true
          },
          {
            title: 'Destination Stations',
            path: PATH_DASHBOARD.stations.destinationStation,
            icon: <EvStationIcon />,
            isItem: true
          }
        ]
      }
    ]
  },

  // IOT
  // ----------------------------------------------------------------------
  {
    subheader: 'IOT',
    items: [
      { title: 'Smart Meter', path: PATH_DASHBOARD.smartMeter.smartMeter, icon: ICONS.dashboard }
    ]
  },

  // Utilities
  // ----------------------------------------------------------------------
  {
    subheader: 'Utilities',
    items: [
      {
        title: 'Electricity Bills',
        path: PATH_DASHBOARD.utilities.root,
        icon: <ReceiptIcon />,
        children: [
          {
            title: 'List',
            path: PATH_DASHBOARD.utilities.bills,
            isItem: true
          },
          {
            title: 'Upload',
            path: PATH_DASHBOARD.utilities.upload,
            isItem: true
          }
        ]
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'MANAGEMENT',
    items: [
      { title: 'User', path: PATH_DASHBOARD.users.root, icon: ICONS.user },
      { title: 'Partner', path: PATH_DASHBOARD.partners.root, icon: <GroupIcon /> },
      {
        title: 'MDM',
        path: PATH_DASHBOARD.mdm.root,
        icon: <ComputerIcon />
      }
    ]
  }
];

const partnerSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.analytics },
      { title: 'Session', path: PATH_DASHBOARD.general.session, icon: <AdjustIcon /> },
      { title: 'Map', path: PATH_DASHBOARD.general.map, icon: <MapIcon /> },
      {
        title: 'Analytics',
        path: PATH_DASHBOARD.analytics.root,
        icon: <DataUsageIcon />,
        children: [
          { title: 'Energy Analytics', path: PATH_DASHBOARD.analytics.energyAnalytics },
          { title: 'Session Analytics', path: PATH_DASHBOARD.analytics.sessionAnalytics }
          // { title: 'User Analytics', path: PATH_DASHBOARD.analytics.userAnalytics },
          // { title: 'Financial Analytics', path: PATH_DASHBOARD.analytics.financialAnalytics }
        ]
      }
    ]
  },

  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'STATION',
    items: [{ title: 'Stations', path: PATH_DASHBOARD.stations.station, icon: <EvStationIcon /> }]
  },

  // IOT
  // ----------------------------------------------------------------------
  {
    subheader: 'IOT',
    items: [
      { title: 'Smart Meter', path: PATH_DASHBOARD.smartMeter.smartMeter, icon: ICONS.dashboard }
    ]
  },

  // Utilities
  // ----------------------------------------------------------------------
  {
    subheader: 'Utilities',
    items: [
      {
        title: 'Electricity Bills',
        path: PATH_DASHBOARD.utilities.root,
        icon: <ReceiptIcon />,
        children: [
          {
            title: 'List',
            path: PATH_DASHBOARD.utilities.bills,
            isItem: true
          },
          {
            title: 'Upload',
            path: PATH_DASHBOARD.utilities.upload,
            isItem: true
          }
        ]
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'MANAGEMENT',
    items: [{ title: 'User', path: PATH_DASHBOARD.users.root, icon: ICONS.user }]
  }
];

const sidebarConfig = { adminSidebarConfig, partnerSidebarConfig };
export default sidebarConfig;
