import qs from 'qs';
// axios instance
import api from 'lib/api';

// get count
export const apiGetStationsCount = async () => {
  const response = await api.get('/dashboard/summaries/stations-count');

  return response.data;
};

export const apiGetChargingPointsCount = async () => {
  const response = await api.get('/dashboard/summaries/charging-points-count');

  return response.data;
};

export const apiGetConnectorsCount = async () => {
  const response = await api.get('/dashboard/summaries/connectors-count');

  return response.data;
};

export const apiGetAvailableConnectorsCount = async () => {
  const response = await api.get(
    '/dashboard/summaries/connectors-count?status=AVAILABLE&disabled_ne=true'
  );

  return response.data;
};

export const apiGetInUseConnectorsCount = async () => {
  const response = await api.get(
    '/dashboard/summaries/connectors-count?status_nin[]=AVAILABLE&status_nin[]=MAINTENANCE&disabled_ne=true'
  );

  return response.data;
};

export const apiGetMaintenanceConnectorsCount = async () => {
  const query = qs.stringify({
    _where: { _or: [{ status: 'MAINTENANCE' }, { disabled: true }] }
  });

  const response = await api.get(`/dashboard/summaries/connectors-count?${query}`);

  return response.data;
};

// get summary
export const apiGetChargingEnergySummary = async ({
  startDate,
  endDate,
  station
}: {
  startDate: string;
  endDate: string;
  station?: string;
}) => {
  const queryString = qs.stringify({
    checkedInAt_gte: startDate,
    checkedInAt_lt: endDate,
    station
  });
  const response = await api.get(`/dashboard/summaries/total-charging-energy?${queryString}`);

  return response.data;
};

export const apiGetChargingDurationSummary = async ({
  startDate,
  endDate,
  station
}: {
  startDate: string;
  endDate: string;
  station?: string;
}) => {
  const queryString = qs.stringify({
    checkedInAt_gte: startDate,
    checkedInAt_lt: endDate,
    station
  });
  const response = await api.get(`/dashboard/summaries/total-charging-duration?${queryString}`);

  return response.data;
};

export const apiGetChargingSessionsSummary = async ({
  startDate,
  endDate,
  station
}: {
  startDate: string;
  endDate: string;
  station?: string;
}) => {
  const queryString = qs.stringify({
    createdAt_gte: startDate,
    createdAt_lt: endDate,
    station
  });
  const response = await api.get(`/dashboard/summaries/charging-sessions-count?${queryString}`);

  return response.data;
};

export const apiGetRevenueSummary = async ({
  startDate,
  endDate,
  station
}: {
  startDate: string;
  endDate: string;
  station?: string;
}) => {
  const queryString = qs.stringify({
    checkedInAt_gte: startDate,
    checkedInAt_lt: endDate,
    station
  });
  const response = await api.get(`/dashboard/summaries/total-revenue?${queryString}`);

  return response.data;
};

// get periodically
export const apiGetChargingEnergyPeriodically = async ({
  startDate,
  endDate,
  periodicity,
  station
}: {
  startDate: string;
  endDate: string;
  periodicity: string;
  station?: string;
}) => {
  const queryString = qs.stringify({
    checkedInAt_gte: startDate,
    checkedInAt_lt: endDate,
    periodicity,
    station
  });
  const response = await api.get(
    `/dashboard/summaries/total-charging-energy-per-periodicity?${queryString}`
  );

  return response.data;
};

export const apiGetRevenuePeriodically = async ({
  startDate,
  endDate,
  periodicity,
  station
}: {
  startDate: string;
  endDate: string;
  periodicity: string;
  station?: string;
}) => {
  const queryString = qs.stringify({
    checkedInAt_gte: startDate,
    checkedInAt_lt: endDate,
    periodicity,
    station
  });
  const response = await api.get(
    `/dashboard/summaries/total-revenue-per-periodicity?${queryString}`
  );

  return response.data;
};

// get top performing
export const apiGetTopPerformingStations = async ({
  startDate,
  endDate,
  _start = 0,
  _limit = 5,
  _sort = 'totalRevenue:DESC'
}: {
  startDate: string;
  endDate: string;
  _start?: number;
  _limit?: number;
  _sort?: string;
}) => {
  const queryString = qs.stringify({
    checkedInAt_gte: startDate,
    checkedInAt_lt: endDate,
    _start,
    _limit,
    _sort
  });
  const response = await api.get(`/dashboard/summaries/top-performing-stations?${queryString}`);

  return response.data;
};

// lean
export const apiGetStationsLean = async () => {
  const response = await api.get(`/dashboard/stations/lean`);

  return response.data;
};

export const apiGetChargingPointsLean = async () => {
  const response = await api.get(`/dashboard/charging-points/lean`);

  return response.data;
};

export const apiGetSmartMetersLean = async () => {
  const response = await api.get(`/dashboard/smart-meters/lean`);

  return response.data;
};
