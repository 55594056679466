import axios from 'utils/axios';
import qs from 'qs';

// Types
import {
  AdminInviteFormData,
  AdminRegisterPayloadType,
  UserListParam,
  UserFormPayload,
  UserUpdatePayload
} from '../../@types/user';

export const apiGetUserMe = async () => {
  let path = `/dashboard/users/me`;
  const response = await axios.get(path);
  return response.data;
};

export const apiUpdateUserMe = async (data: UserFormPayload) => {
  let path = `/dashboard/users/me`;
  const response = await axios.put(path, data);
  return response.data;
};

export const apiGetUserList = async ({
  page,
  limit,
  order,
  orderBy,
  filterName,
  role,
  other
}: UserListParam) => {
  let path = '/users';
  let start = page * limit;
  path += `?_start=${start}&_limit=${limit}`;
  if (orderBy) path += `&_sort=${orderBy}:${order.toUpperCase()}`;
  if (filterName) path += `&_q=${filterName}`;
  if (role) path += `&role.type=${role}`;
  if (other && !!Object.keys(other).length) {
    let otherQueryPath = qs.stringify(other);
    path += `&${otherQueryPath}`;
  }

  const response = await axios.get(path);
  return response.data;
};

export const apiGetUserListCount = async (
  filterName?: string,
  role?: string,
  other?: { [k: string]: any }
) => {
  let path = '/users/count?';
  if (filterName) path += `&_q=${filterName}`;
  if (role) path += `&role.type=${role}`;
  if (other && !!Object.keys(other).length) {
    let otherQueryPath = qs.stringify(other);
    path += `&${otherQueryPath}`;
  }

  const response = await axios.get(path);
  return response.data;
};

export const apiPostInviteAdminUser = async (payload: AdminInviteFormData) => {
  let path = '/register/admin-user';
  const response = await axios.post(path, payload);
  return response.data;
};

export const apiGetAdminInviteInfo = async (token: string) => {
  if (!token) throw new Error('Invalid token');
  let path = `/register/info?confirmationToken=${token}`;
  const response = await axios.get(path);
  return response.data;
};

export const apiPostRegisterAdmin = async (payload: AdminRegisterPayloadType) => {
  let path = '/register/confirmation-token';
  const response = await axios.post(path, payload);
  return response.data;
};

export const apiGetUser = async (id: string, role?: string) => {
  let path = `/users/${id}?`;
  if (role) path += `&role.type=${role}`;

  const response = await axios.get(path);
  return response.data;
};

export const apiPutUser = async (id: string, payload: UserUpdatePayload) => {
  let path = `/users/${id}`;
  const response = await axios.put(path, payload);
  return response.data;
};
