import axios from 'utils/axios';
import qs from 'qs';

export const apiGetNotifications = async (query: Record<string, any>) => {
  const queryString = qs.stringify(query, { encode: false });

  const response = await axios.get(`/dashboard/notifications/me?${queryString}`);
  return response.data;
};

export const apiGetNotificationsCount = async (query: Record<string, any>) => {
  const queryString = qs.stringify(query, { encode: false });

  const response = await axios.get(`/dashboard/notifications/me/count?${queryString}`);

  return response.data;
};

export const apiGetNotificationsPaginationScroll = async ({ queryKey, pageParam = 0 }: any) => {
  const [_, query] = queryKey;
  const queryParam = { ...query, _start: pageParam };

  const [data, count] = await Promise.all([
    apiGetNotifications(queryParam),
    apiGetNotificationsCount(queryParam)
  ]);

  return {
    data,
    nextPage: pageParam + query._limit,
    hasNextPage: data.length + pageParam < count,
    count
  };
};

export const apiPostNotificationsMarkAllAsRead = async () => {
  let path = '/dashboard/notifications/me/mark-all-as-read';
  const response = await axios.post(path);
  return response.data;
};

export const apiPostNotificationsMarkAsRead = async (id: string) => {
  if (!id) throw new Error('undefined id property');
  let path = `/dashboard/notifications/me/${id}/mark-as-read`;
  const response = await axios.post(path);
  return response.data;
};
