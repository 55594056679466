import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import OperatingHoursCreate from 'pages/OperatingHoursCreate';
import OperatingHoursDetail from 'pages/OperatingHoursDetail';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Authentication Routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register/:token',
          element: <Register />
        }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        { path: 'dashboard', element: <Dashboard /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> }
          ]
        },
        { path: 'map', element: <Map /> },
        {
          path: 'session',
          children: [{ element: <SessionList /> }, { path: ':id', element: <SessionDetail /> }]
        },
        {
          path: 'analytics',
          children: [
            { element: <Navigate to="/analytics/energy" replace /> },
            { path: 'energy', element: <EnergyAnalytics /> },
            { element: <Navigate to="/analytics/session" replace /> },
            { path: 'session', element: <SessionAnalytics /> }
            // { element: <Navigate to="/analytics/user" replace /> },
            // { path: 'user', element: <UserAnalytics /> },
            // { element: <Navigate to="/analytics/financial" replace /> },
            // { path: 'financial', element: <FinancialAnalytics /> }
          ]
        },
        {
          path: 'operating-hours',
          // element: <OperatingHour />,
          children: [
            { element: <OperatingHour /> },
            { path: 'create', element: <OperatingHoursCreate /> },
            { path: ':id', element: <OperatingHoursDetail /> }
          ]
        },

        // { path: 'energy-analytics', children: [{ element: <EnergyAnalytics /> }] },
        // { path: 'session-analytics', children: [{ element: <SessionAnalytics /> }] },
        // { path: 'user-analytics', children: [{ element: <UserAnalytics /> }] },
        // { path: 'financial-analytics', children: [{ element: <FinancialAnalytics /> }] },
        {
          path: 'station',
          children: [{ element: <StationList /> }, { path: ':id', element: <StationDetail /> }]
        },
        { path: 'gwm-station', children: [{ element: <StationList /> }] },
        { path: 'partner-station', children: [{ element: <StationList /> }] },
        { path: 'destination-station', children: [{ element: <StationList /> }] },

        {
          path: 'smart-meter',
          children: [{ element: <SmartMeter /> }, { path: ':id', element: <SmartMeter /> }]
        },
        {
          path: 'users',
          children: [{ element: <UserList /> }, { path: ':id', element: <UserDetail /> }]
        },
        {
          path: 'partners',
          children: [{ element: <PartnerList /> }, { path: ':id', element: <PartnerDetail /> }]
        },
        { path: 'mdm/suppliers', element: <MDMSupplierList /> },
        {
          path: 'notifications',
          children: [{ element: <NotificationList /> }]
        },
        {
          path: 'utilities',
          children: [
            { path: 'bills', element: <ElectricityBillList /> },
            { path: 'bills/:id', element: <ElectricityBillDetail /> },
            { path: 'upload', element: <ElectricityBillUpload /> }
          ]
        }
        // {
        //   path: 'station',
        //   children: [
        //     { element: <Navigate to="/station/detail" replace /> },
        //     { path: 'detail', element: <StationDetail /> }
        //   ]
        // }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Map = Loadable(lazy(() => import('../pages/Map')));
const OperatingHour = Loadable(lazy(() => import('../pages/OperatingHours')));
const SessionList = Loadable(lazy(() => import('../pages/SessionList')));
const SessionDetail = Loadable(lazy(() => import('../pages/SessionDetail')));

// Dashboard - Analytics
const EnergyAnalytics = Loadable(lazy(() => import('../pages/EnergyAnalytics')));
const SessionAnalytics = Loadable(lazy(() => import('../pages/SessionAnalytics')));
// const UserAnalytics = Loadable(lazy(() => import('../pages/UserAnalytics')));
// const FinancialAnalytics = Loadable(lazy(() => import('../pages/FinancialAnalytics')));

//Stations
const StationList = Loadable(lazy(() => import('../pages/StationList')));
const StationDetail = Loadable(lazy(() => import('../pages/StationDetail')));

// Iot Device
const SmartMeter = Loadable(lazy(() => import('../pages/SmartMeter')));

// Utilities
const ElectricityBillList = Loadable(lazy(() => import('../pages/ElectricityBillList')));
const ElectricityBillDetail = Loadable(lazy(() => import('../pages/ElectricityBillDetail')));
const ElectricityBillUpload = Loadable(lazy(() => import('../pages/ElectricityBillUpload')));

// Management
const UserList = Loadable(lazy(() => import('../pages/UserList')));
const UserDetail = Loadable(lazy(() => import('../pages/UserDetail')));
const UserProfile = Loadable(lazy(() => import('../pages/MyAccountGeneral')));
const PartnerList = Loadable(lazy(() => import('../pages/PartnerList')));
const PartnerDetail = Loadable(lazy(() => import('../pages/PartnerDetail')));
const MDMSupplierList = Loadable(lazy(() => import('../pages/MDMSupplierList')));

// Others
const NotificationList = Loadable(lazy(() => import('../pages/NotificationList')));
const NotFound = Loadable(lazy(() => import('../pages/others/Page404')));
