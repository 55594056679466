import { atom } from 'recoil';
import { AuthState } from '../../@types/authentication';

export const authenticationState = atom<AuthState>({
  key: 'authenticationState',
  default: {
    isAuthenticated: false,
    isInitialized: false,
    user: null
  }
});
