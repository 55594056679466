// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  createNewPassword: path(ROOTS_AUTH, '/create-new-password')
};

// ----------------------------------------------------------------------
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile')
  },
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    session: path(ROOTS_DASHBOARD, '/session'),
    map: path(ROOTS_DASHBOARD, '/map'),
    operatingHour: path(ROOTS_DASHBOARD, '/operating-hours'),
    operatingHourCreate: path(ROOTS_DASHBOARD, '/operating-hours/create')
  },
  stations: {
    station: path(ROOTS_DASHBOARD, '/station'),
    gwmStation: path(ROOTS_DASHBOARD, '/gwm-station'),
    partnerStation: path(ROOTS_DASHBOARD, '/partner-station'),
    destinationStation: path(ROOTS_DASHBOARD, 'destination-station')
  },
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics'),
    energyAnalytics: path(ROOTS_DASHBOARD, '/analytics/energy'),
    sessionAnalytics: path(ROOTS_DASHBOARD, '/analytics/session'),
    userAnalytics: path(ROOTS_DASHBOARD, '/analytics/user'),
    financialAnalytics: path(ROOTS_DASHBOARD, '/analytics/financial')
  },
  sessions: {
    session: path(ROOTS_DASHBOARD, '/session'),
    detail: path(ROOTS_DASHBOARD, '/session/detail')
  },
  smartMeter: {
    smartMeter: path(ROOTS_DASHBOARD, '/smart-meter')
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users')
  },
  partners: {
    root: path(ROOTS_DASHBOARD, '/partners'),
    pageSeven: path(ROOTS_DASHBOARD, '/partners/seven'),
    detail: path(ROOTS_DASHBOARD, '/partners/detail')
  },
  mdm: {
    root: path(ROOTS_DASHBOARD, '/mdm/suppliers')
  },
  notifications: {
    root: path(ROOTS_DASHBOARD, '/notifications')
  },
  utilities: {
    root: path(ROOTS_DASHBOARD, '/utilities'),
    bills: path(ROOTS_DASHBOARD, '/utilities/bills'),
    upload: path(ROOTS_DASHBOARD, '/utilities/upload')
  }
};
