import { QueryClient, QueryClientProvider } from 'react-query';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';
// hooks
import { useAuth, useInitialAuth } from 'features/authentication';
import WithAxios from 'utils/withAxios';
// ----------------------------------------------------------------------

const queryClient = new QueryClient();

export default function App() {
  useInitialAuth();
  const { isInitialized } = useAuth();

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <WithAxios>
        <ThemeConfig>
          <ThemePrimaryColor>
            <RtlLayout>
              <NotistackProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                {/* <Settings /> */}
                <ScrollToTop />
                {isInitialized ? <Router /> : <LoadingScreen />}
              </NotistackProvider>
            </RtlLayout>
          </ThemePrimaryColor>
        </ThemeConfig>
      </WithAxios>
    </QueryClientProvider>
  );
}
