import { useParams } from 'react-router';

// material
import { Container, Grid } from '@mui/material';

// hooks
import useSettings from 'hooks/useSettings';
import { useGetOperatingHourById } from 'features/operatingHours/operatingHours';

// components
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import OperatingHoursForm from 'components/OperatingHours/OperatingHoursForm';

// constants
import { PATH_DASHBOARD } from 'routes/paths';
import LoadingScreen from 'components/LoadingScreen';

export default function OperatingHoursDetail() {
  const { themeStretch } = useSettings();
  const { id = ' ' } = useParams();

  const { data, isLoading, isFetching } = useGetOperatingHourById(id);

  if (isFetching) return <LoadingScreen />;

  return (
    <Page title="Operating Hours - GWM">
      <Container maxWidth={themeStretch ? false : 'xxl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderBreadcrumbs
              heading={data?.name || ''}
              links={[
                { name: 'Operating Hours', href: PATH_DASHBOARD.general.operatingHour },
                { name: 'Detail' }
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            {data && !isLoading && <OperatingHoursForm data={data} isEdit id={id} />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
