import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// api
import {
  apiGetOperatingHour,
  apiGetOperatingHourList,
  apiGetOperatingHourListCount,
  apiPostOperatingHour,
  apiPutOperatingHour
} from './apis';

// types
import { StationType } from '../../@types/station';
import { TableListParam } from '../../@types/general';
import { OperatingHour, OperatingHourFormType } from '../../@types/operatingHourSchedule';

// utils
import { DEFAULT_LIMIT } from 'utils/constant';
import { handleError } from 'utils/error';
import { PATH_DASHBOARD } from 'routes/paths';
import moment from 'moment';

export const useGetOperatingHours = (
  {
    page = 0,
    limit = DEFAULT_LIMIT,
    order = 'desc',
    orderBy = '',
    filterName = '',
    other = {}
  }: TableListParam,
  option = {}
) => {
  const queryClient = useQueryClient();

  const query = useQuery<StationType[], Error>(
    ['getOperatingHourList', page, limit, order, orderBy, filterName, other],
    () => apiGetOperatingHourList({ page, limit, order, orderBy, filterName, other }),
    {
      refetchOnWindowFocus: false,
      ...option
    }
  );

  const queryCount = useQuery<number, Error>(
    ['getOperatingHourListCount', page, limit, filterName],
    () => apiGetOperatingHourListCount(filterName, other),
    {
      refetchOnWindowFocus: false,
      ...option
    }
  );

  useEffect(() => {
    const total = queryCount?.data || 0;
    const dataLength = query?.data?.length || 0;
    const hasMore = page * limit + dataLength < total;
    if (hasMore) {
      queryClient.prefetchQuery(
        ['getOperatingHourList', page + 1, limit, order, orderBy, other],
        () => apiGetOperatingHourList({ page: page + 1, limit, order, orderBy, filterName, other })
      );
    }
  }, [query?.data, page, queryClient, queryCount?.data, limit, order, orderBy, other, filterName]);

  return {
    ...query,
    data: query?.data || [],
    count: queryCount?.data || 0
  };
};

export const useGetOperatingHourById = (id: string) => {
  const query = useQuery<OperatingHour, Error>(
    ['getOperatingHour', id],
    () => apiGetOperatingHour(id),
    { refetchOnWindowFocus: false }
  );

  return {
    ...query,
    data: query?.data || null
  };
};

export const useOperatingHours = ({
  isEdit = false,
  id = ''
}: {
  isEdit?: boolean;
  id?: string;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutation = useMutation((payload: OperatingHour) => apiPostOperatingHour(payload));
  const mutationEdit = useMutation((payload: OperatingHour) => apiPutOperatingHour(id, payload), {
    onSuccess: () => queryClient.invalidateQueries('getOperatingHour')
  });

  const submitOperatingHour = async (data: OperatingHourFormType) => {
    let operatingDaysObj: any = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    };

    for (const day of data.operatingDays) {
      operatingDaysObj[day?.value?.toLowerCase() as any] = true;
    }

    let payload: OperatingHour = {
      ...data,
      ...operatingDaysObj,
      openingHour: data.openingHour
        ? moment(data.openingHour).format('HH:mm:ss.SSS')
        : data.openingHour,
      closingHour: data.closingHour
        ? moment(data.closingHour).format('HH:mm:ss.SSS')
        : data.closingHour,
      chargingPoints: data.chargingPoints.map((i) => i.id || i)
    };

    if (!isEdit) {
      // create
      try {
        const response = await mutation.mutateAsync(payload);
        enqueueSnackbar('Create Success', { variant: 'success' });
        navigate(`${PATH_DASHBOARD.general.operatingHour}/${response.id}`);
      } catch (error: any) {
        enqueueSnackbar(handleError(error)?.message || 'Failed to Create', { variant: 'error' });
      }
    } else {
      // edit
      try {
        await mutationEdit.mutateAsync(payload);
        enqueueSnackbar('Edit Success', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(handleError(error)?.message || 'Failed to Edit', { variant: 'error' });
      }
    }
  };

  return {
    submitOperatingHour
  };
};
