// material
import { Container, Grid } from '@mui/material';

// hooks
import useSettings from 'hooks/useSettings';

// components
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import OperatingHoursForm from 'components/OperatingHours/OperatingHoursForm';

// constants
import { PATH_DASHBOARD } from 'routes/paths';

export default function OperatingHoursCreate() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Operating Hours - GWM">
      <Container maxWidth={themeStretch ? false : 'xxl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderBreadcrumbs
              heading="Create New Operating Hours Schedule"
              links={[
                { name: 'Operating Hours', href: PATH_DASHBOARD.general.operatingHour },
                { name: 'Create New' }
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <OperatingHoursForm />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
