const DEFAULT_MESSAGE = 'Something went wrong, please try again.';

interface ErrorObjectType {
  type: string;
  statusCode: string | null;
  message: string;
}

const recursiveError = (key: string, item: any): any => {
  if (
    item &&
    (item.hasOwnProperty(key) ||
      item.hasOwnProperty('messages') || // hard 'messages' key for strapi error
      item.constructor === Array)
  ) {
    const isArray = item.constructor === Array;
    const res = recursiveError(
      key,
      isArray ? item[0] : item[key] || item?.messages // hard 'messages' key for strapi error
    );
    if (res === null) {
      return item[key]; // the deepest err message
    } else {
      return res;
    }
  }
  return null; // the deepest position
};

export const getErrorMessage = (error: any): string => {
  if (typeof error === 'string' || error instanceof String) {
    return error as string;
  }

  if (typeof error === 'object' || error instanceof Object) {
    return recursiveError('message', error);
  }

  return DEFAULT_MESSAGE;
};

export const handleError = (error: any): ErrorObjectType => {
  const errorObj = {
    type: 'error',
    statusCode: error?.statusCode || null,
    message: getErrorMessage(error)
  };

  return errorObj;
};
