import moment from 'moment';
import { AnalyticFilterType } from '../@types/analytic';

export const DEFAULT_LIMIT = 10;

export const STATION_STATUS = {
  open: 'OPEN',
  closed: 'CLOSED',
  maintenance: 'MAINTENANCE',
  inUse: 'IN_USE',
  soon: 'COMING_SOON'
};

export const CONNECTOR_STATUS = {
  available: 'AVAILABLE',
  preparing: 'PREPARING',
  charging: 'CHARGING',
  maintenance: 'MAINTENANCE'
};

export const SESSION_STATUS = {
  created: 'CREATED',
  reserved: 'RESERVED',
  checkedIn: 'CHECKED_IN',
  charging: 'CHARGING',
  stopped: 'STOPPED',
  stoppedFullyCharge: 'STOPPED_FULLY_CHARGE',
  stoppedError: 'STOPPED_BY_ERROR',
  stoppedUnplug: 'STOPPED_BY_UNPLUG',
  checkedOut: 'CHECKED_OUT',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED'
};

export const ELECTRICITY_BILL_STATUS = {
  pending: 'PENDING',
  approved: 'APPROVED',
  rejected: 'REJECTED'
};

export const SESSION_STATUS_LABEL_COLOR = {
  default: [SESSION_STATUS.created, SESSION_STATUS.reserved],
  info: [SESSION_STATUS.checkedIn, SESSION_STATUS.checkedOut],
  success: [SESSION_STATUS.charging, SESSION_STATUS.completed],
  warning: [SESSION_STATUS.stopped, SESSION_STATUS.stoppedFullyCharge],
  error: [SESSION_STATUS.stoppedError, SESSION_STATUS.stoppedUnplug, SESSION_STATUS.cancelled]
};

export const ELECTRICITY_BILL_STATUS_LABEL_COLOR = {
  warning: [ELECTRICITY_BILL_STATUS.pending],
  success: [ELECTRICITY_BILL_STATUS.approved],
  error: [ELECTRICITY_BILL_STATUS.rejected]
};

export const NOTIFICATION_ACTIONS = {
  openChargePointDetails: 'OPEN_CHARGING_POINT_DETAILS',
  openChargingSessionDetails: 'OPEN_CHARGING_SESSION_DETAILS',
  openStationDetails: 'OPEN_STATION_DETAILS',
  openElectricityBillDetails: 'OPEN_ELECTRICITY_BILL_DETAILS',
  openPartnerDetails: 'OPEN_PARTNER_DETAILS'
};

export const DEFAULT_START_DATE = moment().startOf('month').toISOString();
export const DEFAULT_END_DATE = moment().add(1, 'month').startOf('month').toISOString();

export const DEFAULT_TOTAL_ENERGY_MIN = 0;
export const DEFAULT_TOTAL_ENERGY_MAX = 200;
export const DEFAULT_TOTAL_ENERGY = [DEFAULT_TOTAL_ENERGY_MIN, DEFAULT_TOTAL_ENERGY_MAX];

const ANALYTICS_VALUE = {
  DAILY_SUM: 'DAILY-SUM',
  DAILY_AVERAGE: 'DAILY-AVERAGE',
  MONTHLY_SUM: 'MONTHLY-SUM',
  MONTHLY_AVERAGE: 'MONTHLY-AVERAGE',
  CHARGING_DURATION: 'CHARGING-DURATION',
  CHECKED_IN_TIME: 'CHECKED-IN-TIME',
  EV_MODEL: 'EV-MODEL'
};

export const ANALYTIC_OPTIONS = {
  energy: [
    { title: 'Daily Sum up', value: ANALYTICS_VALUE.DAILY_SUM },
    { title: 'Daily Average', value: ANALYTICS_VALUE.DAILY_AVERAGE },
    { title: 'Monthly Sum up', value: ANALYTICS_VALUE.MONTHLY_SUM },
    { title: 'Monthly Average', value: ANALYTICS_VALUE.MONTHLY_AVERAGE }
  ],
  session: [
    { title: 'Charging duration', value: ANALYTICS_VALUE.CHARGING_DURATION },
    { title: 'Checked-in Time', value: ANALYTICS_VALUE.CHECKED_IN_TIME }
    // { title: 'EV model', value: ANALYTICS_VALUE.EV_MODEL }
  ],
  financial: [
    { title: 'Charging Duration', value: ANALYTICS_VALUE.MONTHLY_SUM },
    { title: 'Checked-in Time', value: ANALYTICS_VALUE.MONTHLY_AVERAGE }
    // { title: 'EV Model', value: ANALYTICS_VALUE.MONTHLY_AVERAGE }
  ],
  user: [
    { title: 'Charging Duration', value: ANALYTICS_VALUE.MONTHLY_SUM },
    { title: 'Checked-in Time', value: ANALYTICS_VALUE.MONTHLY_AVERAGE }
    // { title: 'EV Model', value: ANALYTICS_VALUE.MONTHLY_AVERAGE }
  ]
};

export const ANALYTIC_FILENAME = {
  [ANALYTICS_VALUE.DAILY_SUM]: 'daily-sum',
  [ANALYTICS_VALUE.DAILY_AVERAGE]: 'daily-average',
  [ANALYTICS_VALUE.MONTHLY_SUM]: 'monthly-sum',
  [ANALYTICS_VALUE.MONTHLY_AVERAGE]: 'monthly-average',
  [ANALYTICS_VALUE.CHARGING_DURATION]: 'charging-duration',
  [ANALYTICS_VALUE.CHECKED_IN_TIME]: 'checkin-time',
  [ANALYTICS_VALUE.EV_MODEL]: 'ev-model',
  DEFAULT: 'analytics'
};

export const MONTHS = [
  { label: 'Jan', value: 0 },
  { label: 'Feb', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Apr', value: 3 },
  { label: 'May', value: 4 },
  { label: 'Jun', value: 5 },
  { label: 'Jul', value: 6 },
  { label: 'Aug', value: 7 },
  { label: 'Sep', value: 8 },
  { label: 'Oct', value: 9 },
  { label: 'Nov', value: 10 },
  { label: 'Dec', value: 11 }
];

export const REGIONS = [
  { label: 'Northern', value: 'NORTHERN' },
  { label: 'Northeastern', value: 'NORTHEASTERN' },
  { label: 'Middle', value: 'MIDDLE' },
  { label: 'Eastern', value: 'EASTERN' },
  { label: 'Southern', value: 'SOUTHERN' },
  { label: 'Western', value: 'WESTERN' }
];

export const STATION_TYPES = [
  { label: 'SS (Supercharging Station)', value: 'SUPERCHARGING' },
  { label: 'PS (Partner Station)', value: 'PARTNER' },
  { label: 'DS (Destination Station)', value: 'DESTINATION' }
];

export const DEFAULT_ANALYTIC_OPTION_FILTER: AnalyticFilterType = {
  regions: REGIONS.map((region) => region.value),
  stationTypes: [],
  stations: []
};
