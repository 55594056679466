import axios from 'utils/axios';
import qs from 'qs';

import { TableListParam } from '../../@types/general';

export const apiGetOperatingHourList = async ({
  page,
  limit,
  order,
  orderBy,
  filterName,
  other
}: TableListParam) => {
  let path = '/operating-hours-schedules';
  let start = limit > 0 ? page * limit : 0;
  path += `?_start=${start}&_limit=${limit}`;
  if (orderBy) path += `&_sort=${orderBy}:${order.toUpperCase()}`;
  if (filterName) path += `&_q=${filterName}`;

  if (other && !!Object.keys(other).length) {
    let otherQueryPath = qs.stringify(other);
    path += `&${otherQueryPath}`;
  }

  const response = await axios.get(path);
  return response.data;
};

export const apiGetOperatingHourListCount = async (
  filterName?: string,
  other?: { [key: string]: any }
) => {
  let path = '/operating-hours-schedules/count?';
  if (filterName) path += `&_q=${filterName}`;

  if (other && !!Object.keys(other).length) {
    let otherQueryPath = qs.stringify(other);
    path += `&${otherQueryPath}`;
  }

  const response = await axios.get(path);
  return response.data;
};

export const apiGetOperatingHour = async (id: string) => {
  if (!id) throw new Error('undefined id property');
  const queryString = qs.stringify({}, { encode: false });
  let path = `/operating-hours-schedules/${id}?${queryString}`;
  const response = await axios.get(path);
  return response.data;
};

export const apiPostOperatingHour = async (payload: any) => {
  let path = `/operating-hours-schedules`;
  const response = await axios.post(path, payload);
  return response.data;
};

export const apiPutOperatingHour = async (id: string, payload: any) => {
  let path = `/operating-hours-schedules/${id}`;
  const response = await axios.put(path, payload);
  return response.data;
};

export const apiDeleteOperatingHours = async (ids: string[]) => {
  if (!ids.length) throw new Error('undefined id property');
  const path = `/operating-hours-schedules/delete/many`;
  const payload = { ids };
  const response = await axios.post(path, payload);
  return response.data;
};
