import { Stack, Typography } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

type NoNotificationsPropType = {
  iconSize?: number;
};

export default function NoNotifications({ iconSize = 100 }: NoNotificationsPropType) {
  return (
    <Stack
      direction="column"
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ my: 10 }}
    >
      <NotificationsNoneIcon sx={{ fontSize: iconSize, color: '#b6b6b6', mb: 2 }} />
      <Typography variant="subtitle2">No Notifications Yet</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        When you get notifications, they will show up here.
      </Typography>
    </Stack>
  );
}
