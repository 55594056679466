import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'utils/axios';
import { isValidToken, setSession } from 'utils/jwt';
// apis
import { apiUpdateUserMe } from 'features/user/apis';

// Types
import { UserFormPayload } from '../../@types/user';

// stores
import { authenticationState } from 'stores/authentication';

export const useInitialAuth = () => {
  const [_, setAuth] = useRecoilState(authenticationState);
  useEffect(() => {
    (async function initialize() {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/users/me');

          setAuth({ isInitialized: true, isAuthenticated: true, user: response.data });
        } else {
          setAuth({ isInitialized: true, isAuthenticated: false, user: null });
        }
      } catch (err) {
        setAuth({ isInitialized: true, isAuthenticated: false, user: null });
      }
    })();
  }, []);
};

export const useAuth = () => {
  const [authState, setAuth] = useRecoilState(authenticationState);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/auth/local', {
      identifier: email,
      password
    });

    let { jwt, user } = response.data;

    setSession(jwt);
    setAuth((current) => ({ ...current, isAuthenticated: true, user }));
  };

  const logout = async () => {
    setSession(null);
    setAuth((current) => ({ ...current, isAuthenticated: false, user: null }));
  };

  const updateUserMe = async (data: UserFormPayload) => {
    const response = await apiUpdateUserMe(data);
    setAuth((current) => ({ ...current, user: response }));
  };

  return {
    ...authState,
    login,
    logout,
    updateUserMe
  };
};
