import { noCase } from 'change-case';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { formatDistanceToNow } from 'date-fns';

// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Stack
} from '@mui/material';

// Icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// utils
import { DEFAULT_LIMIT, NOTIFICATION_ACTIONS } from 'utils/constant';
import { handleError } from 'utils/error';

// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import NoNotifications from 'components/notification/NoNotifications';

// hooks
import { useGetNotifications } from 'features/notification/notification';

// apis
import { apiPostNotificationsMarkAsRead } from 'features/notification/apis';

// types
import { Notification } from '../../@types/notification';

function NotificationItem({ data, onClick }: { data: Notification; onClick?: VoidFunction }) {
  const isUnread = true;
  const { title, message, createdAt, isRead } = data;

  return (
    <ListItemButton
      // to="#"
      // component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!isRead && {
          bgcolor: 'action.selected'
        })
      }}
      onClick={() => onClick?.()}
    >
      <ListItemAvatar>
        <FiberManualRecordIcon color={'warning'} />
      </ListItemAvatar>

      <ListItemText
        primary={
          <Stack>
            <Typography variant="subtitle2">{title || '-'}</Typography>
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              {message || ''}
            </Typography>
          </Stack>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(createdAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const { data } = useGetNotifications({ _limit: 10, _sort: 'createdAt:DESC' });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const totalUnRead = data?.filter((item) => !item.isRead).length;

  const mutateMarkAsRead = useMutation((id: string) => apiPostNotificationsMarkAsRead(id));
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notificationOnClickAction = async (item: Notification) => {
    const stationDetailAction = async () => {
      if (!item?.station) return;
      await navigate(`/station/${item.station}`);
    };

    const notificationActionState = {
      [NOTIFICATION_ACTIONS.openStationDetails]: stationDetailAction
    };
    return item?.onClickAction ? notificationActionState[item?.onClickAction]?.() : null;
  };

  const handleNotificationOnClick = async (item: Notification) => {
    try {
      if (!item.isRead) {
        const response = await mutateMarkAsRead.mutateAsync(item._id);
        queryClient.invalidateQueries('getNotificationPaginationScroll');
        queryClient.invalidateQueries('getNotifications');
        enqueueSnackbar('Marked as read');
      }
      notificationOnClickAction(item);
    } catch (error) {
      enqueueSnackbar(handleError(error)?.message || 'Fail mark as read', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 40,
          height: 40,
          mx: 1,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        {/* <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider /> */}

        <Scrollbar sx={{ height: { xs: 340, sm: '70vh' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {!data.length && (
              <>
                <Divider />
                <NoNotifications iconSize={60} />
              </>
            )}
            {!!data.length &&
              data?.map((item) => (
                <NotificationItem
                  key={item.id}
                  data={item}
                  onClick={() => handleNotificationOnClick(item)}
                />
              ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to="/notifications"
            onClick={handleClose}
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
